<script>
    import RoundsNav from './RoundsNav.svelte';
    import BannedModal from './BannedModal.svelte';
    import SearchBox from '@/Components/SearchBox.svelte';
    import SuggestionsBox from '@/Components/SuggestionsBox.svelte';
    import * as DropdownMenu from '$lib/components/ui/dropdown-menu';
    import { Menu, ArrowRight } from 'lucide-svelte';
    import { inertia } from '@inertiajs/svelte';

    export let suggestions;
    export let rounds;
    export let userRoundScore;
    export let baseScoreToBan;
    export let hasTwitterConnected;
    export let theme;
    export let sidebarSpot;
    export let suggestionSlider;
</script>

<div class="container">
    <div class="row">
        <div class="w-full p-0 md:w-3/5 lg:w-2/3">
            <div class="flex items-center justify-between px-3 pt-3">
                <div class="">
                    <h5 class="mb-2 font-bold">Rounds</h5>
                    <h6 class="flex mr-4 mt-2 text-muted">Repost eachother and grow together</h6>
                </div>
                <div>


                    <DropdownMenu.Root>
                        {#if hasTwitterConnected}
                            <DropdownMenu.Trigger>
                                <button
                                    type="button"
                                    class="m-0 block px-2 py-1 text-{theme} text-gray-800 hover:text-gray-600 md:hidden {userRoundScore <=
                                    baseScoreToBan
                                        ? 'cursor-not-allowed'
                                        : ''}"
                                    disabled="{userRoundScore <= baseScoreToBan}"
                                >
                                    <Menu />
                                </button>
                            </DropdownMenu.Trigger>
                        {/if}

                        <DropdownMenu.Content>
                            <DropdownMenu.Group>
                                <DropdownMenu.Item>
                                    <a href="{'/rounds'}" use:inertia class="p-2">{'Overview'}</a>
                                </DropdownMenu.Item>
                                <DropdownMenu.Item>
                                    <a href="{'/rounds/my'}" use:inertia class="p-2">{'Rounds participating'}</a>
                                </DropdownMenu.Item>
                                <DropdownMenu.Item>
                                    <a href="{'/rounds/history'}" use:inertia class="p-2">{'Rounds history'}</a>
                                </DropdownMenu.Item>
                            </DropdownMenu.Group>
                        </DropdownMenu.Content>
                    </DropdownMenu.Root>
                </div>
            </div>
            <hr />
            <div class="{userRoundScore <= baseScoreToBan ? 'd-none' : ''}}">
                {#if hasTwitterConnected}
                    <div class="hidden md:block">
                        <RoundsNav />
                    </div>
                {/if}
            </div>
            <div class="m-3">
                <div class="px-4 sm:px-6 lg:px-8">
                    <div class="flow-root">
                        <div class="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
                            <div class="inline-block min-w-full py-2 align-middle">
                                <table class="min-w-full border-separate border-spacing-0">
                                    <caption class="caption-bottom text-center text-xs">Rounds history </caption>
                                    {#each rounds as round, index}
                                        {#if index === 0}
                                            <thead>
                                                <tr>
                                                    {#each ['Round', 'Participants', 'Points gained', 'Date'] as title}
                                                        <th
                                                            scope="col"
                                                            class="sticky top-0 z-10 border-b py-3.5 pl-4 pr-3
                                                                text-center text-sm font-semibold backdrop-blur
                                                                backdrop-filter sm:pl-6 lg:pl-8"
                                                        >
                                                            {title}</th
                                                        >
                                                    {/each}
                                                </tr>
                                            </thead>
                                        {/if}

                                        <tbody>
                                            <tr>
                                                <td
                                                    class="whitespace-nowrap border-b py-4 pl-4 pr-3 text-center
                                                        text-sm font-medium text-muted sm:pl-6 lg:pl-8"
                                                >
                                                    {round.min_follower_count}+</td
                                                >
                                                <td
                                                    class="whitespace-nowrap border-b py-4 pl-4 pr-3 text-center
                                                        text-sm font-medium text-muted sm:pl-6 lg:pl-8"
                                                >
                                                    {round.participants_number}</td
                                                >
                                                <td
                                                    class="whitespace-nowrap border-b py-4 pl-4 pr-3 text-center
                                                        text-sm font-medium text-muted sm:pl-6 lg:pl-8"
                                                >
                                                    {round.score_change}</td
                                                >
                                                <td
                                                    class="whitespace-nowrap border-b py-4 pl-4 pr-3 text-center
                                                        text-sm font-medium text-muted sm:pl-6 lg:pl-8"
                                                >
                                                    {round.start_timestamp}
                                                </td>
                                            </tr>
                                        </tbody>
                                    {:else}
                                        <tr>
                                            <td colspan="4" class="text-center py-4"
                                                >You have not completely participated in any rounds yet, go to the <a
                                                    href="{'/rounds'}"
                                                    use:inertia>overview page</a
                                                > to check for available rounds.
                                            </td>
                                        </tr>
                                    {/each}
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="suggestions-wrapper hidden min-h-screen w-full border-l pb-5 pt-4 md:block md:w-2/5 lg:w-1/3">
            <div class="feed-widgets">
                <div class="mb-4">
                    <SearchBox />
                </div>
                {#if !suggestionSlider}
                    <SuggestionsBox {suggestions} isMobile="{false}" />
                {/if}
                {#if sidebarSpot}
                    <div class="mt-4">
                        {sidebarSpot}
                    </div>
                {/if}
            </div>
        </div>
    </div>
</div>
